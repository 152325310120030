<template lang="">
  <div v-if="propPayoutDetails">
    <img :src="propPayoutDetails.payout_tid_img"  style="width: 100%"/>
    <h4 class="mt-4">Banking Transaction Id: <span class="primary-color">{{propPayoutDetails.payout_tid}}</span></h4>
    <h4 class="mt-4">Amount Paid(INR) <span class="primary-color">{{propPayoutDetails.payout_amount}}</span></h4>
  </div>
</template>
<script>
export default {
  name: "PayoutDetailsView",
  props: {
    propPayoutDetails: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="">

</style>
