<template lang="">
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <b-row class="row mt-2">
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <h4><i class="fa fa-solid ri-user-star-fill primary-color mr-2" aria-hidden="true"></i>&nbsp;
                  {{`${orgData.org_name} ${cvCardTitle}`}}&nbsp;
                  <i title="FAQ" class="ri-question-line pointer primary-color" style="font-size: 25px;" @click="gotoFAQList()"></i><br>
                </h4><br>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="head_buttons pull-right">
                  <b-btn variant="primary" class="mr-2 mb-2" @click="goToDashboard" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">Dashboard</b-btn>
                  <b-btn variant="primary" class="mr-2 mb-2" @click="goToRevenueDashboard">Revenue</b-btn>
                </div>
              </b-col>
            </b-row>
          </template>
          <template>
            <div class="row p-0 mb-4">
              <!-- Total Payout -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div class="card text-white mb-2 cardBoxs" style="background-color:#994499">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2" v-if="userData.user_country == 99">
                      Total Payout<br>(INR)<br>
                      <b>{{redemptionAnalytics.total_payout_inr ? redemptionAnalytics.total_payout_inr.toLocaleString() : 0}}</b>
                    </div>
                    <div class="mr-2" v-else  >
                      Total Payout<br>(USD)<br>
                      <b>{{redemptionAnalytics.total_payout_usd ? redemptionAnalytics.total_payout_usd.toLocaleString() : 0}}</b>
                    </div>
                    <h4 style="color:#fff"></h4>
                  </div>
                </div>
              </div><!-- Total Payout -->

              <!-- Commission Not Redeemed -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div class="card text-white mb-2 cardBoxs" style="background-color:#109618">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2" v-if="userData.user_country == 99">
                      Not Redeemed<br>(INR)<br>
                      <b v-if="userBillDetails.bill_gstin">{{redemptionAnalytics.total_not_redeemed_inr_with_gst.toLocaleString()}}</b>
                      <b v-else>{{redemptionAnalytics.total_not_redeemed_inr_without_gst.toLocaleString()}}</b>
                    </div>
                    <div class="mr-2" v-else  >
                      Not Redeemed<br>(USD)<br>
                      <b v-if="userBillDetails.bill_gstin">{{redemptionAnalytics.total_not_redeemed_inr_with_gst.toLocaleString()}}</b>
                      <b v-else>{{redemptionAnalytics.total_not_redeemed_inr_without_gst.toLocaleString()}}</b>
                    </div>
                    <h4 style="color:#fff"></h4>
                  </div>
                </div>
              </div><!-- Commission Not Redeemed -->

              <!-- Commission To Be Recovered-->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div class="card text-white mb-2 cardBoxs" style="background-color:#c63310">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2" v-if="userData.user_country == 99">
                      Recovering<br>(INR)<br>
                      <b v-if="userBillDetails.bill_gstin">{{redemptionAnalytics.total_tobe_deducted_inr_with_gst.toLocaleString()}}</b>
                      <b v-else>{{redemptionAnalytics.total_tobe_deducted_inr_without_gst.toLocaleString()}}</b>
                    </div>
                    <div class="mr-2" v-else  >
                      Recovering<br>(USD)<br>
                      <b v-if="userBillDetails.bill_gstin">{{redemptionAnalytics.total_tobe_deducted_inr_with_gst.toLocaleString()}}</b>
                      <b v-else>{{redemptionAnalytics.total_tobe_deducted_inr_without_gst.toLocaleString()}}</b>
                    </div>
                    <h4 style="color:#fff"></h4>
                  </div>
                </div>
              </div><!-- Commission To Be Recovered-->

              <!-- Commission To Be Recovered-->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <div class="card text-white mb-2 cardBoxs" style="background-color:#e58900">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2" v-if="userData.user_country == 99">
                      Inprogress<br>(INR)<br>
                      <b v-if="userBillDetails.bill_gstin">{{redemptionAnalytics.total_inprogress_inr_with_gst.toLocaleString()}}</b>
                      <b v-else>{{redemptionAnalytics.total_inprogress_inr_without_gst.toLocaleString()}}</b>
                    </div>
                    <div class="mr-2" v-else  >
                      Inprogress<br>(USD)<br>
                      <b v-if="userBillDetails.bill_gstin">{{redemptionAnalytics.total_inprogress_inr_with_gst.toLocaleString()}}</b>
                      <b v-else>{{redemptionAnalytics.total_inprogress_inr_without_gst.toLocaleString()}}</b>
                    </div>
                    <h4 style="color:#fff"></h4>
                  </div>
                </div>
              </div><!-- Commission To Be Recovered-->

            </div>
          </template>
          <template>
            <b-row class="mb-4">
              <!-- Backend Search -->
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <input class="form-control"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchRedemptionDashboard"
                  :placeholder="cvSearchText"
                />
              </b-col><!-- Backend Search -->

              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="pull-right">
                  <b-btn variant="primary" class="mr-2 mb-1" @click="backendCsvDownload">CSV</b-btn>
                  <b-btn variant="primary" class="mr-2 mb-1" @click="openBankingDetailsModal">Bank Account</b-btn>
                  <b-btn variant="primary" class="mr-2 mb-1" @click="editTaxInfo(saasOrgData)">Tax Info</b-btn>
                  <b-btn variant="primary" class="mr-2 mb-1" @click="showblogViewDialog()">Invoice Template</b-btn>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="container_minheight" md="12" v-if="redemptionDashboardObjList && redemptionDashboardObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="redemptionDashboardObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <!--
                   "TOBEOTPVERF": "To Be OTP Verified",
                  "TOBEAPPROVED": "To Be Approved",
                  "NOTREDEEMED": "Click to Redeem",
                  "TOBEPAID": "To Be Paid",
                  "PAID": "Paid",
                  "TOBERECOVERED": "To Be Recovered",
                  "DEDUCTED": "Deducted",
                  "NOT_ELIGIBLE": "Not Eligible for Redemption"
                  -->
                  <template v-slot:cell(pay_redeem_id)="data">
                    <div class="pull-left">
                    <span v-if="data.item.redemption_status == 'TOBEPAID'" title="Awaiting Verification & Payment">
                      <b-badge :style="'background:'+getBgFor(data.item.redemption_status)">
                        {{redemptionStatusStringObj[data.item.redemption_status]}}
                      </b-badge>&nbsp;
                    </span>
                    <span v-else-if="data.item.redemption_status == 'NOTREDEEMED'">
                      <span v-if="moment().diff(moment(data.item.redemption_period), 'months') >= 2" title="Awaiting Redemption" class="pointer" @click="raiseRedemptionReqConfirmation(data.item)">
                        <b-badge :style="'background:'+getBgFor(data.item.redemption_status)">
                          {{redemptionStatusStringObj[data.item.redemption_status]}}
                        </b-badge>
                      </span>
                      <span v-else>
                        <b-badge :style="'background:'+getBgFor('NOT_ELIGIBLE')" title="Only Revenue generated through the end of the previous month is available for redemption">
                          {{redemptionStatusStringObj["NOT_ELIGIBLE"]}}
                        </b-badge>&nbsp;<i title="FAQ" class="ri-question-line pointer primary-color" style="font-size: 15px;" @click="gotoFAQList()"></i>
                      </span>
                    </span>
                    <span v-else-if="data.item.redemption_status == 'TOBEOTPVERF'" title="Awaiting OTP Verification" class="pointer" @click="raiseRedemptionReqConfirmation(data.item)">
                      <b-badge :style="'background:'+getBgFor(data.item.redemption_status)">
                        {{redemptionStatusStringObj[data.item.redemption_status]}}
                      </b-badge>&nbsp;
                    </span>
                    <span v-else-if="data.item.redemption_status == 'PAID'" title="Redeem & Payment Complete" class="pointer" @click="raiseRedemptionReqConfirmation(data.item)">
                      <b-badge :style="'background:'+getBgFor(data.item.redemption_status)">
                        {{redemptionStatusStringObj[data.item.redemption_status]}}
                      </b-badge>&nbsp;
                    </span>
                    <span v-else-if="data.item.redemption_status == 'TOBERECOVERED'" title="Adjusted for Refund" class="pointer" @click="raiseRedemptionReqConfirmation(data.item)">
                      <b-badge :style="'background:'+getBgFor(data.item.redemption_status)">
                        {{redemptionStatusStringObj[data.item.redemption_status]}}
                      </b-badge><br>
                      <span><small>Student has filed for Refund. This amount will be adjusted in your next payment</small></span>
                    </span>
                    <span v-else>
                      <b-badge :style="'background:'+getBgFor(data.item.redemption_status)">
                        {{redemptionStatusStringObj[data.item.redemption_status]}}
                      </b-badge>
                    </span>
                    <i v-if="data.item.invoice" class="fa-lg pointer fa-solid fa-file-invoice" @click="openUploadGstInvoiceModal(data.item)" title="View/Update Invoice"></i>&nbsp;
                    <i v-if="data.item.payout_tid" class="fa-lg pointer fa-solid fa-file-invoice-dollar ml-2" @click="openPayoutDetailsViewModal(data.item)" title="View Payment Details"></i><br>
                    <span v-if="data.item.pay_redeem_id"><small><b>Id:&nbsp;{{data.item.pay_redeem_id}}</b></small></span>
                  </div>
                  </template>

                  <template v-slot:cell(redemption_period)="data">
                    <span class="pull-left">F.Y.&nbsp;{{data.item.redemption_period}}</span>
                  </template>

                  <template v-slot:cell(revenue)="data">
                    <!-- {{data.item.pay_status_code}}<br> -->
                    <span class="pull-right">{{data.item.revenue.toLocaleString()}}</span><br>
                  </template>
                  <template v-slot:cell(commission_inr_with_gst)="data">
                    <span class="pull-right">{{data.item.commission_inr_with_gst.toLocaleString()}}</span>
                  </template>
                  <template v-slot:cell(commission_inr_without_gst)="data">
                    <span class="pull-right">{{data.item.commission_inr_without_gst.toLocaleString()}}</span>
                  </template>
                  <template v-slot:cell(commission_usd_with_gst)="data">
                    <span class="pull-right">{{data.item.commission_usd_with_gst.toLocaleString()}}</span>
                  </template>
                  <template v-slot:cell(commission_usd_without_gst)="data">
                    <span class="pull-right">{{data.item.commission_usd_without_gst.toLocaleString()}}</span>
                  </template>

                  <template v-slot:cell(payout_amount)="data">
                    <span class="pull-right" v-if="data.item.payout_amount">{{data.item.payout_amount.toLocaleString()}}&nbsp;
                      <span v-if='data.item.redemption_status == "PAID"' class="success-color">Cr.</span>
                      <span v-else class="warning-color">Dr.</span>
                    </span>
                  </template>
                  <template v-slot:cell(payout_tid)="data">
                    <div class="pull-left" v-if="data.item.payout_tid">
                      <small><b>
                      <span>Payout Date:&nbsp;{{data.item.payout_date}}</span><br>
                      <span>Payout Id:&nbsp;{{data.item.payout_tid}}</span>
                      </b></small>
                    </div>
                  </template>
                </b-table>
              </b-col>
              <b-col v-else class="container_minheight" md="12">
                <!-- No Data Found -->
                Hey it looks like your students have not enrolled for any of the GIDE.AI products like Test Preparation, Self Paced Testseries, Premium Admission Counseling etc. Once your students enrol on any of the services, GIDE.AI shall allocate the commission as per your plan. The revenue and commission is visible under the Revenue section of your dashboard.
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" class="mb-1" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />

    <!-- Banking Details Modal -->
    <b-modal size="xl" v-model="showBankDetailsModal" :title="'Bank Details'" no-close-on-backdrop no-close-on-esc @hide="closeBankDetailsModal">
      <form action="#">
        <ValidationObserver ref="bankDetailsAddForm" class="row w-100 m-0">
          <div class="row w-100 ml-0">
            <!-- Account Holder Name -->
            <div class="col-12 mb-2">
              <label for="validationslot_acHolderName">Account Holder Name</label>&nbsp;<label class="primary-color font-size-16">*</label>
              <ValidationProvider name="Account Holder Name" rules="required|min:2" v-slot="{ errors }">
                <input v-model="vmBankDetailsFormData.ac_holder_name" type="text" class="form-control" required/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div><!-- Account Holder Name -->

            <!-- Bank Name -->
            <div class="col-12 mb-2">
              <label for="validationslot_bankName">Bank Name</label>&nbsp;<label class="primary-color font-size-16">*</label>
              <ValidationProvider name="Bank Name" rules="required|min:2" v-slot="{ errors }">
                <input v-model="vmBankDetailsFormData.bank_name" type="text" class="form-control" required/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div><!-- Bank Name -->

            <!-- Branch/Location -->
            <div class="col-12 mb-2">
              <label for="validationslot_bankName">Branch/Location</label>&nbsp;<label class="primary-color font-size-16">*</label>
              <ValidationProvider name="Branch/Location" rules="required|min:2" v-slot="{ errors }">
                <input v-model="vmBankDetailsFormData.branch" type="text" class="form-control" required/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div><!-- Branch/Location -->

            <!-- Account No. -->
            <div class="col-12 mb-2">
              <label for="validationslot_bankName">Account No.</label>&nbsp;<label class="primary-color font-size-16">*</label>
              <ValidationProvider name="Account No." rules="required|min:2" v-slot="{ errors }">
                <input v-model="vmBankDetailsFormData.ac_no" type="text" class="form-control" required/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div><!-- Account No. -->

            <!-- IFSC Code for India. -->
            <div class="col-12 mb-2">
              <label for="validationslot_bankName">IFSC Code</label>&nbsp;
              <ValidationProvider name="IFSC Code" v-slot="{ errors }">
                <input v-model="vmBankDetailsFormData.ifsc_code" type="text" class="form-control"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div><!-- IFSC Code for India. -->

            <!-- SWIFT/BIC Code for International transfers. -->
            <div class="col-12 mb-2">
              <label for="validationslot_bankName">SWIFT/BIC Code</label>&nbsp;
              <ValidationProvider name="SWIFT/BIC Code" v-slot="{ errors }">
                <input v-model="vmBankDetailsFormData.swift_code" type="text" class="form-control"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div><!-- SWIFT/BIC Code for International transfers. -->

            <!-- Checque Book Image -->
            <div class="col-md-12">
              <label>Cheque Book Screen Shot : {{ imageSize }} <span class="primary-color font-size-16">*</span></label>
              <div class="form-group row align-items-center ml-1">
                <div class="profile-img-edit border" style="height: 200px; width: 300px;">
                  <img v-if="vmChequeImg" :src="vmChequeImg" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                  <i v-else class="fas fa-image d-flex justify-content-center align-items-center pointer" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                  <label class="p-image">
                    <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                    <input class="file-upload" type="file" required accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event)" />
                  </label>
                </div>
                <span class="text-danger" ref="coverPicError"></span>
              </div>
            </div><!-- Checque Book Image -->
          </div>
        </ValidationObserver>
      </form>
      <p>
        <button type="button" class="btn btn-primary ml-1 mt-1" @click="saveBankingDetails()">Save</button>
      </p>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" variant="secondary" @click="closeBankDetailsModal()">
          {{propCancelBtnText}}
        </b-button>
      </template>
    </b-modal><!-- Banking Details Modal -->

    <!-- Payout Details View -->
    <b-modal size="xl" v-model="showPayoutDetailsViewModal" :title="'Payout Details'" no-close-on-backdrop no-close-on-esc hide-footer @hide="closePayoutDetailsViewModal">
      <PayoutDetails :propPayoutDetails="propPayoutDetails"/>
    </b-modal><!-- Payout Details View -->

    <!-- Redemption Req Confirmation Modal -->
    <b-modal size="xl" v-model="showRedemptionReqCnfModal" :title="'Confirmation'" no-close-on-backdrop no-close-on-esc @hide="closeRedemptionReqCnfModal">
      <b-button size="sm" class="pull-right" variant="primary" @click="editTaxInfo(saasOrgData)">
        Tax Info
      </b-button>
      <span v-if="showRedemptionBeforeThirdWarning">
        Thank you for your redemption request on {{redemptionReqDate}}. Processing will commence on {{redemptionProcessDate}}. Your commission will be deposited into your registered bank account. Kindly verify and update your bank details before proceeding.
      </span><br/>
      <div class="mt-4">
        <span v-if="bankDetailsList && bankDetailsList.length > 0 && bankDetailsList[0]">
          <b>Account Holder:</b>&nbsp; {{bankDetailsList[0].ac_holder_name}}<br/>
          <b>Bank:</b>&nbsp; {{bankDetailsList[0].bank_name}}<br/>
          <b>Branch:</b>&nbsp; {{bankDetailsList[0].branch}}<br/>
          <b>Account No:</b>&nbsp; {{bankDetailsList[0].ac_no}}<br/>
          <b>IFSC Code:</b>&nbsp; {{bankDetailsList[0].ifsc_code}}<br/>
          <b>SWIFT Code:</b>&nbsp; {{bankDetailsList[0].swift_code}}<br/>
          <span v-if="redeemReq">
            <b>Redeeming For Period:</b>&nbsp; {{redeemReq.redemption_period}}<br/>
          </span>
          <span v-if="userData.user_country == 99 && redeemReq">
            <b>Redemption Amount(GST: 18%):</b>&nbsp;INR:&nbsp; {{redeemReq.commission_inr_with_gst}}<br/>
            <b>Redemption Amount:</b>&nbsp;INR:&nbsp; {{redeemReq.commission_inr_without_gst}}<br/>
          </span>
          <span v-else-if="redeemReq">
            <b>Redemption Amount(GST: 18%):</b>&nbsp;USD:&nbsp; {{redeemReq.commission_usd_with_gst}}<br/>
            <b>Redemption Amount:</b>&nbsp;USD:&nbsp; {{redeemReq.commission_usd_without_gst}}<br/>
          </span>
          <div v-if="!showRedeemOtpVerification && redeemReq && redeemReq.redemption_status == 'NOTREDEEMED'" class="mt-2">
            <b-button size="sm" class="pull-left" variant="primary" @click="raiseRedemptionRequest()">
              {{propOkBtnText}}
            </b-button>
          </div>
          <!-- OTP verification input box -->
          <div class="mt-5" v-else-if="showRedeemOtpVerification">
            <label for="validationslot_redeemOtp">Redemption OTP</label>&nbsp;
            <ValidationProvider name="redeemOtp" v-slot="{ errors }">
              <input v-model="redeemOtp" type="text" class="form-control w-150px"/>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>

            <b-button size="sm" class="pull-left mt-2 mr-2" variant="warning" @click="resendRedemptionOtp()">
              Resend OTP
            </b-button>
            <b-button size="sm" class="pull-left mt-2" variant="primary" @click="redeemOtpVerify()">
              Verify OTP
            </b-button>
          </div><!-- OTP verification input box -->
        </span>
        <div v-else>
          <span class="primary-color">Please add your bank account details before proceeding with the redemption</span><br/>
          <b-btn variant="primary" class="" @click="openBankingDetailsModal">Add Bank Account</b-btn>
        </div>
      </div>
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" variant="secondary" @click="closeRedemptionReqCnfModal()">
          {{propCancelBtnText}}
        </b-button>
      </template>
    </b-modal><!-- Redemption Req Confirmation Modal -->

    <!-- GST Invoice Upload Modal -->
    <b-modal size="xl" v-model="showGstInvoiceUploadModal" :title="'Invoice'" no-close-on-backdrop no-close-on-esc @hide="closeGstInvoiceUploadModalModal">
      <UploadDocs :propDocsList="vmGstInvoiceFormData" :propUploadInputLabel="`Attach Invoices`" :propAllowedExtensions="`application/pdf`" @emitGetLatestDocs="emitGetLatestDocs" />
      <p v-if="userData.user_role == 'USERROLE11116' && (!redeemReq || !redeemReq.invoice)">
        <button type="button" class="btn btn-primary ml-1 mt-1" @click="uploadGstInvoice()">Upload GST Invoice</button>
      </p>
      <p v-else-if="userData.user_role == 'USERROLE11116' && redeemReq && redeemReq.invoice">
        <button type="button" class="btn btn-primary ml-1 mt-1" @click="editGstInvoice()">Edit GST Invoice</button>
      </p>

      <template #modal-footer="">
        <b-button size="sm" class="pull-left" variant="secondary" @click="closeBankDetailsModal()">
          {{propCancelBtnText}}
        </b-button>
      </template>
    </b-modal><!-- GST Invoice Upload Modal -->

   <!-- OrgsaastaxDocument -->
   <b-modal
      v-model="showTaxInfoDocumentModal"
      scrollable
      :title="cvTaxDocumentModalHeader"
      size="lg"
    >
    <OrgSaasTaxDocuments :propModuleObjId = "saasOrgDataObj" :propOpenedInModal="true" @emitTaxInfoCloseEditModal="closeTaxInfoEditModal"/>
      <template #modal-footer="">
        <b-button size="sm" @click="closeTaxInfoEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- OrgsaastaxDocument -->

    <!-- Gide BLog View For Sample INVOICE -->
    <b-modal
      v-model="showModelblogView"
      scrollable
      :title="cvViewModalHeader"
      size="xxl"
    >
      <GideBlogView :propOpenedInModal="true" :propBlogVueObj="blogViewObj" @emitCloseblogViewModal="closeblogViewModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeblogViewModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Gide BLog View For Sample INVOICE -->

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { socialvue } from "../../../../config/pluginInit.js"
import { PayRedemption } from "../../../../FackApi/api/PayRedemption.js"
import ApiResponse from "../../../../Utils/apiResponse.js"
import { UserBillDetails } from "../../../../FackApi/api/UserBillDetails"
import moment from "moment"
import { Organisations } from "../../../../FackApi/api/organisation"
import OrgSaasTaxDocuments from "../../../../views/Gide/Organisation/OrgSaasTaxDocuments.vue"
import PayoutDetails from "./PayoutDetailsView.vue"
import GideBlogView from "../../../Public/GideBlogView.vue"
import UploadDocs from "../../../../components/UploadDocs.vue"
import CropImage from "../../../../components/cropImage.vue"
import ColorCodes from "../../../../Utils/colorcodes.js"
import userPermission from "../../../../Utils/user_permission.js"
import RedemptionStatusJson from "../../../../FackApi/json/RedemptionStatus.json"

export default {
  name: "OrgSaasRedemptionDashboard",
  components: {
    PayoutDetails,
    CropImage,
    OrgSaasTaxDocuments,
    GideBlogView,
    UploadDocs
  },
  data () {
    return {
      apiName: "pay_redemption_dashboard_list",
      INVOICE_FAQ_BLOG_ID: "BLOGSAASORGInvoiceTemplateId0001",
      cvCardTitle: "Redemption Dashboard",
      whereFilter: {
        search_param: ""
      },
      redemptionStatusStringObj: RedemptionStatusJson,
      payStatusStringObj: {
        "PGW_CAPTURED": "Payment success",
        "PGW_SUCCESS": "Payment success",
        "PGW_REFUNDED": "Payment Refunded"
      },
      redemptionAnalytics: {
        total_inprogress_inr_with_gst: 0,
        total_inprogress_inr_without_gst: 0,
        total_inprogress_usd_with_gst: 0,
        total_inprogress_usd_without_gst: 0,
        total_not_redeemed_inr_with_gst: 0,
        total_not_redeemed_inr_without_gst: 0,
        total_not_redeemed_usd_with_gst: 0,
        total_not_redeemed_usd_without_gst: 0,
        total_payout_inr: 0,
        total_tobe_deducted_inr_with_gst: 0,
        total_tobe_deducted_inr_without_gst: 0,
        total_tobe_deducted_usd_without_gst: 0
      },
      org_id: null,
      redemptionDashboardObjList: null,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Redemption Dashboard",
      showToast: false,
      columns: [],
      cvSearchText: "Type to search...",
      showBankDetailsModal: false,
      propCancelBtnText: "Close",
      propOkBtnText: "Proceed",
      bankDetailsList: [],
      vmBankDetailsFormData: {},
      showRedemptionReqCnfModal: false,
      showRedemptionBeforeThirdWarning: true,
      redemptionReqDate: null,
      redemptionProcessDate: null,
      userBillDetails: null,
      orgData: {},
      redeemReq: null,
      redemptionParticularsObjList: [],
      redemeedAmt: 0,
      fileSrc: "",
      showLoadMoreBtn: true,
      totalRows: 1,
      dataPerPage: 100,
      sortBy: "",
      sortDesc: false,
      filter: null,
      filterOn: [],
      currentBatchNo: 1,
      cvTaxDocumentModalHeader: "Tax Info",
      showTaxInfoDocumentModal: false,
      cvbtnModalCancel: "Close",
      saasOrgDataObj: null,
      showModelblogView: false,
      blogViewObj: null,
      cvViewModalHeader: "Invoice Template",
      showGstInvoiceUploadModal: false,
      vmGstInvoiceFormData: [],
      propCropImageDialogOpen: false,
      propStencilSize: null,
      propCropDialogSize: "md",
      imageSize: "Size  1080 * 566",
      vmChequeImg: null,
      chequeImgList: [],
      redeemOtp: null,
      showRedeemOtpVerification: false,
      redemption_period: null,
      propPayoutDetails: null,
      showPayoutDetailsViewModal: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role.
    if (!(this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11116" || this.userData.user_role == "USERROLE11118")) {
      this.$router.back()
    }

    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    if (this.userData.user_country == 99) {
      this.columns = [
        { label: "#", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Redemption Status", key: "pay_redeem_id", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Redemption Period", key: "redemption_period", class: "text-left align-text-top", sortable: true },
        { label: "Revenue", key: "revenue", class: "text-right align-text-top", sortable: true },
        // { label: "Commission with GST (INR)", key: "commission_inr_with_gst", class: "text-right align-text-top", sortable: true },
        { label: "Commission without GST (INR)", key: "commission_inr_without_gst", class: "text-right align-text-top", sortable: true },
        { label: "Payout", key: "payout_amount", class: "text-right align-text-top", sortable: true },
        { label: "Payout Details", key: "payout_tid", class: "text-left align-text-top w-150px", sortable: true }
      ]
    }
    else {
      this.columns = [
        { label: "#", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Redemption Id", key: "pay_redeem_id", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Redemption Period", key: "redemption_period", class: "text-left align-text-top", sortable: true },
        { label: "Revenue", key: "revenue", class: "text-right align-text-top", sortable: true },
        // { label: "Commission Amount USD with GST(18%)", key: "commission_usd_with_gst", class: "text-right align-text-top", sortable: true },
        { label: "Commission Amount USD without GST(18%)", key: "commission_usd_without_gst", class: "text-right align-text-top", sortable: true },
        { label: "Payout", key: "payout_amount", class: "text-right align-text-top", sortable: true },
        { label: "Payout Details", key: "payout_tid", class: "text-left align-text-top w-150px", sortable: true }
      ]
    }

    socialvue.index()

    if (this.$route.query.org_id) {
      if ((this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
        this.orgId = this.$route.query.org_id
        await this.getOrgDetails()
      }
    }
    else if (!this.saasOrgData || this.saasOrgData.org_admin_email != this.userData.user_email) {
      // orgadmin visits GIDE from the platform's login page
      const otherOrgId = Object.keys(this.userData.modules.organisations)
      if (otherOrgId && this.userData && this.userData.roles[otherOrgId].udr_name) {
        this.orgId = otherOrgId
        await this.getOrgDetails()
      }
    }
    else {
      // orgadmin visits GIDE from his/her own login page
      this.orgId = this.saasOrgData.org_id
      this.orgData = this.saasOrgData
    }

    this.getRedemptionDashboardData()
    this.getRedemptionAnalytics()
    this.getBankDetailsOfUser()
  },
  methods: {
    /**
     * Calling Function as per whereFilter
    */
    async getRedemptionDateWise () {
      await this.getRedemptionDashboardData()
    },
    /**
     * getBgFor
     */
    getBgFor (redemptionStatus) {
      switch (redemptionStatus) {
        case "TOBEOTPVERF":
          return ColorCodes.getBgColor(50)
        case "TOBEAPPROVED":
          return ColorCodes.getBgColor(1)
        case "NOTREDEEMED":
          return ColorCodes.getBgColor(15)
        case "TOBEPAID":
          return ColorCodes.getBgColor(13)
        case "PAID":
          return ColorCodes.getBgColor(3)
        case "TOBERECOVERED":
          return ColorCodes.getBgColor(9)
        case "DEDUCTED":
          return ColorCodes.getBgColor(30)
        case "PGW_CAPTURED":
          return ColorCodes.getBgColor(32) // Teal Green
        case "PGW_SUCCESS":
          return ColorCodes.getBgColor(32) // Teal Green
        case "PGW_REFUNDED":
          return ColorCodes.getBgColor(15) // Red with orange tint
        case "NOT_ELIGIBLE":
        default:
          return ColorCodes.getBgColor(4)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      this.vmChequeImg = img
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      this.chequeImgList.push(imgObj.orgImage)
    },
    /*
     * openImageDialog
     *
     */
    openImageDialog (e) {
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        if (!allowedExtension.includes(image.type)) {
          this.toastMsg = `Screenshot of cheque leaf should have one of the following extensions ${allowedExtension.join(", ")}`
          this.showToast = true
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          this.toastMsg = `Screenshot should be of size: ${maxSize}`
          this.showToast = true
          return false
        }
        this.propStencilSize = {
          "width": 540,
          "height": 283
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        const orgDomain = window.location.hostname
        const orgDetails = await Organisations.organisationView(this, this.orgId, orgDomain)
        if (!orgDetails.resp_status) {
          return
        }
        this.orgData = orgDetails.resp_data.data

        // set the org data in local storage for saving in user table
        this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    /**
     * formatDateRange
     */
    formatDateRange () {
      return `[${this.whereFilter.dateRange.startDate.toLocaleDateString("en-CA")} - ${this.whereFilter.dateRange.endDate.toLocaleDateString("en-CA")}]`
    },
    /**
     * getRedemptionAnalytics
     */
    async getRedemptionAnalytics () {
      try {
        const payload = {
          org_id: this.orgId,
          org_admin_email: this.orgData.org_admin_email
        }
        const redemptionAnalyticsResp = await PayRedemption.payRedemptionAnalyticsGet(this, payload)
        if (redemptionAnalyticsResp.resp_status) {
          this.redemptionAnalytics = { ...this.redemptionAnalytics, ...redemptionAnalyticsResp.resp_data.data }
        }
      }
      catch (err) {
        console.error("Exception in getRedemptionAnalytics() and err: ", err.message)
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * getRedemptionDashboardData
     */
    async getRedemptionDashboardData (loadViaLoadMore = false, downloadCsv = false) {
      try {
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.downloadCsv = downloadCsv

        const payload = {
          org_id: this.orgId,
          org_admin_email: this.orgData.org_admin_email
        }
        const redemptionDashboardList = await PayRedemption.payRedemptionDashboardList(this, payload, this.whereFilter)
        if (!redemptionDashboardList.resp_status) {
          ApiResponse.responseMessageDisplay(this, redemptionDashboardList)
          if (this.currentBatchNo > 1) {
            // anything less than 1 (which is the first page) becomes an invalid prop for the b-pagination component
            this.currentBatchNo--
          }
          this.showLoadMoreBtn = false
          return
        }

        if (downloadCsv) {
          window.open(redemptionDashboardList.resp_csv_file_url)
          return
        }

        if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
          this.redemptionDashboardObjList = [...redemptionDashboardList.resp_data.data]
        }
        else {
          this.redemptionDashboardObjList = [...this.redemptionDashboardObjList, ...redemptionDashboardList.resp_data.data]
        }
        this.redemptionDashboardObjList = redemptionDashboardList.resp_data.data
        this.redemptionDashboardObjList.forEach((e, index) => {
          e.id = index + 1
        })
        this.totalRows = this.redemptionDashboardObjList.length

        // Filter out the non-eligible and do a reindexing redemptions just to avoid confusion
        this.redemptionDashboardObjList = this.redemptionDashboardObjList.filter(e => e.redemption_status != "NOT_ELIGIBLE") // && e.redemption_status != "TOBEDEDUCTED"
        this.redemptionDashboardObjList.forEach((e, index) => {
          e.id = index + 1
        })
      }
      catch (err) {
        console.error("Exception in getRedemptionDashboardData() and err: ", err.message)
      }
    },
    /**
     * getRedemptionParticulars
     */
    async getRedemptionParticulars () {
      try {
        const payload = {
          org_id: this.orgData.org_id,
          redemption_period: this.redeemReq.redemption_period,
          bill_gstin: this.userBillDetails.bill_gstin,
          org_admin_email: this.orgData.org_admin_email
        }
        console.log(this.userBillDetails)
        let redemptionParticulars = await PayRedemption.payRedemptionGetParticulars(this, payload)
        return redemptionParticulars
      }
      catch (err) {
        console.error("Exception in getRedemptionParticulars() and err: ", err.message)
      }
    },
    /**
     * raiseRedemptionReqConfirmation
     */
    async raiseRedemptionReqConfirmation (item) {
      this.redeemReq = item
      if (!this.orgData.org_pancard_no) {
        this.toastMsg = "Please add/update your Tax Info like PAN card no. GST details before raising a redemption request"
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      let redeemableAmount = await this.getRedemptionParticulars()
      if (!redeemableAmount.resp_status) {
        ApiResponse.responseMessageDisplay(this, redeemableAmount)
        return
      }
      else {
        redeemableAmount = redeemableAmount.resp_data
        if ((this.userData.user_country == 99 && redeemableAmount.final_amt_inr <= 0) || (this.userData.user_country != 99 && redeemableAmount.final_amt_usd <= 0)) {
          this.toastMsg = `Insufficient balance for processing the request for the period ${this.redeemReq.redemption_period}, try raising a request for some other month`
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
      }

      if (item.redemption_status == "TOBEOTPVERF") {
        this.showRedeemOtpVerification = true
        this.showRedemptionReqCnfModal = true
        return
      }

      if (this.userBillDetails.bill_gstin) {
        this.openUploadGstInvoiceModal(item)
        if (item.redemption_status == "NOTREDEEMED") {
          this.vmGstInvoiceFormData = []
        }
        return
      }
      else {
        // show a warning to the user that he/she is about to raise a redemption req without GST, as a reminder for him/her to update the GST number
        this.toastMsg = "You are about to raise a redemption request without GST, if you feel to proceed the request with GST, kindly update your Tax Info"
        this.toastVariant = "warning"
        this.showToast = true
      }

      let currentDate = moment().format("YYYY-MM-DD")
      let lastDateOfMakingRedemptionReq = moment(`${item.redemption_period}-03`).format("YYYY-MM-DD")
      if (currentDate > lastDateOfMakingRedemptionReq) {
        this.showRedemptionBeforeThirdWarning = true
        this.redemptionReqDate = moment().format("MMM DD, YYYY")
        this.redemptionProcessDate = moment().add(1, "months").startOf("month").date(7).format("MMM DD, YYYY")
      }
      else {
        this.showRedemptionBeforeThirdWarning = false
      }

      this.showRedemptionReqCnfModal = true
    },
    /**
     * raiseRedemptionRequest
     */
    async raiseRedemptionRequest () {
      try {
        const payload = {
          org_id: this.orgId,
          redemption_period: this.redeemReq.redemption_period,
          gidepreneur_user_id: this.saasOrgData.org_admin_user_id
        }

        if (this.vmGstInvoiceFormData.length > 0) {
          payload.invoice = this.vmGstInvoiceFormData
        }
        const raiseRedemptionReqResp = await PayRedemption.payRedemptionRaiseReq(this, payload)
        if (!raiseRedemptionReqResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, raiseRedemptionReqResp)
          return
        }
        this.redeemReq.pay_redeem_id = raiseRedemptionReqResp.resp_data.data.pay_redeem_id
        this.showRedeemOtpVerification = true
      }
      catch (err) {
        console.error("Exception in raiseRedemptionRequest() and err: ", err.message)
      }
    },
    /**
     * getBankDetailsOfUser
     */
    async getBankDetailsOfUser () {
      try {
        let userBillDetails = await UserBillDetails.UserBillDetailsView(this, { org_admin_email: this.orgData.org_admin_email })
        if (userBillDetails.resp_status) {
          this.userBillDetails = userBillDetails.resp_data.data
          if (this.userBillDetails.bank_ac) {
            try {
              this.userBillDetails.bank_ac = JSON.parse(this.userBillDetails.bank_ac)
            }
            catch (jsonParseErr) {
              console.error("Exception in parsing bank a/c details", jsonParseErr)
            }

            if (this.userBillDetails.bank_ac.length > 0) {
              this.bankDetailsList = this.userBillDetails.bank_ac
              this.vmBankDetailsFormData = this.bankDetailsList[0]

              if (JSON.parse(this.vmBankDetailsFormData.cheque_img).length) {
                this.vmChequeImg = JSON.parse(this.vmBankDetailsFormData.cheque_img)[0].url
                this.chequeImgList = JSON.parse(this.vmBankDetailsFormData.cheque_img)
              }
            }
          }
        }
      }
      catch (err) {
        console.error("Exception in getBankDetailsOfUser() and err: ", err.message)
      }
    },
    /**
     * openBankingDetailsModal
     * currently we are supporting only one bank account
     */
    async openBankingDetailsModal () {
      try {
        // This modal can also be opend from the confirmation modal, so closing the confirmation modal first befor opening the banking details modal
        if (this.showRedemptionReqCnfModal) {
          this.showRedemptionReqCnfModal = false
        }

        this.showBankDetailsModal = true
      }
      catch (err) {
        console.error("Exception in openBankingDetailsModal() and err: ", err.message)
      }
    },
    /**
     * closeBankDetailsModal
     */
    closeBankDetailsModal () {
      this.getBankDetailsOfUser()
      this.showBankDetailsModal = false
    },
    /**
     * saveBankingDetails
     */
    async saveBankingDetails () {
      try {
        if (!this.vmChequeImg) {
          this.toastMsg = "Checque leaf image is mandatory"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        const details = {
          ac_holder_name: this.vmBankDetailsFormData.ac_holder_name,
          bank_name: this.vmBankDetailsFormData.bank_name,
          branch: this.vmBankDetailsFormData.branch,
          ac_no: this.vmBankDetailsFormData.ac_no
        }
        if (this.vmBankDetailsFormData.ifsc_code) {
          details.ifsc_code = this.vmBankDetailsFormData.ifsc_code
        }
        if (this.vmBankDetailsFormData.swift_code) {
          details.swift_code = this.vmBankDetailsFormData.ifsc_code
        }
        this.bankDetailsList = [details]

        let bankDetailsAddUpdateResp = null
        if (this.userBillDetails && this.userBillDetails.bill_id) {
          bankDetailsAddUpdateResp = await UserBillDetails.UserBillDetailsEdit(this, { bill_id: this.userBillDetails.bill_id, bank_ac: JSON.stringify(this.bankDetailsList), cheque_img_list: this.chequeImgList })
          if (!bankDetailsAddUpdateResp.resp_status) {
            ApiResponse.responseMessageDisplay(this, bankDetailsAddUpdateResp)
            return
          }
        }
        else {
          bankDetailsAddUpdateResp = await UserBillDetails.UserBillDetailsAdd(this, { bill_name: this.userData.user_name, bank_ac: JSON.stringify(this.bankDetailsList), cheque_img_list: this.chequeImgList })
          if (!bankDetailsAddUpdateResp.resp_status) {
            ApiResponse.responseMessageDisplay(this, bankDetailsAddUpdateResp)
            return
          }
        }

        this.showBankDetailsModal = false
      }
      catch (err) {
        console.error("Exception in saveBankingDetails() and err: ", err.message)
      }
    },
    /**
     * closeRedemptionReqCnfModal
     */
    closeRedemptionReqCnfModal () {
      this.showRedemptionReqCnfModal = false
      // There can be multile redemption reqs in various statges so reset the showVerifyOtp
      this.showRedeemOtpVerification = false
    },
    /**
     * searchRedemptionDashboard
     */
    async searchRedemptionDashboard () {
      this.getRedemptionDashboardData()
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.getRedemptionDashboardData(false, true)
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.getRedemptionDashboardData(true)
    },
    /**
     * closeGstInvoiceUploadModalModal
     */
    closeGstInvoiceUploadModalModal () {
      this.showGstInvoiceUploadModal = false
    },
    /**
     * emitGetLatestDocs
     */
    emitGetLatestDocs (updatedDocsList) {
      this.vmGstInvoiceFormData = updatedDocsList
    },
    /**
     * openUploadGstInvoiceModal
     */
    openUploadGstInvoiceModal (item) {
      try {
        this.redeemReq = item
        if (item.invoice) {
          this.vmGstInvoiceFormData = JSON.parse(item.invoice)
        }
        this.showGstInvoiceUploadModal = true
      }
      catch (err) {
        console.error("Exception in openUploadGstInvoiceModal() and err: ", err.message)
      }
    },
    /**
     * uploadGstInvoice
     */
    uploadGstInvoice () {
      if (this.vmGstInvoiceFormData.length == 0) {
        this.toastMsg = "Please upload GST Invoices"
        this.toastVariant = "danger"
        this.showToast = true
        return
      }
      this.showGstInvoiceUploadModal = false
      this.showRedemptionReqCnfModal = true
    },
    /**
     * edit Gst Invoice
     */
    async editGstInvoice () {
      try {
        const payload = {
          pay_redeem_id: this.redeemReq.pay_redeem_id,
          invoice: (this.vmGstInvoiceFormData)
        }
        const gstInvoiceEditResp = await PayRedemption.payredeemEdit(this, payload)
        ApiResponse.responseMessageDisplay(this, gstInvoiceEditResp)
      }
      catch (err) {
        console.error("Exception in editGstInvoice() and err: ", err.message)
      }
    },
    /**
     * edit TaxInfo
     */
    editTaxInfo (item) {
      try {
        this.saasOrgDataObj = item.org_id
        this.showTaxInfoDocumentModal = true
      }
      catch (err) {
        console.error("Exception occurred at editTaxInfo() and Exception:", err.message)
      }
    },
    /**
     * Close Tax Info
     */
    async closeTaxInfoEditModal () {
      try {
        // It may happen that immediately after upadting the tax info the user tries to raise a redemption req so awaiting the data before closing the modal
        await this.getOrgDetails()
        this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
        await this.getBankDetailsOfUser()

        if (this.showRedemptionReqCnfModal) {
          // If the user has updated the tax info from the confirmation box
          this.showRedemptionReqCnfModal = false
        }

        this.showTaxInfoDocumentModal = false
      }
      catch (err) {
        console.error("Exception occurred at closeTaxInfoEditModal() and Exception:", err.message)
      }
    },
    /**
     * show blog View Dialog
     */
    showblogViewDialog () {
      try {
        this.blogViewObj = {
          blog_id: this.INVOICE_FAQ_BLOG_ID
        }
        this.showModelblogView = true
      }
      catch (err) {
        console.error("Exception occurred at showblogViewDialog() and Exception:", err.message)
      }
    },
    /**
     * closeblogViewModal
     */
    closeblogViewModal () {
      try {
        this.showModelblogView = false
      }
      catch (err) {
        console.error("Exception occurred at closeblogViewModal() and Exception:", err.message)
      }
    },
    /**
     * redeemOtpVerify
     */
    async redeemOtpVerify () {
      try {
        const payload = {
          org_id: this.orgId,
          redeem_otp: this.redeemOtp,
          pay_redeem_id: this.redeemReq.pay_redeem_id,
          redemption_period: this.redeemReq.redemption_period,
          invoce: this.redeemReq.invoice
        }

        if (this.userData.user_country == 99) {
          payload.redemption_amt = `INR(18% GST): ${this.redeemReq.commission_inr_with_gst} / INR: ${this.redeemReq.commission_inr_without_gst}`
        }
        else {
          payload.redemption_amt = `USD(18% GST): ${this.redeemReq.commission_usd_with_gst} / USD: ${this.redeemReq.commission_usd_without_gst}`
        }

        const verifyOtpResp = await PayRedemption.payRedemptionOtpVerify(this, payload)
        ApiResponse.responseMessageDisplay(this, verifyOtpResp)

        if (verifyOtpResp.resp_status) {
          await this.getRedemptionDashboardData()
          this.showRedeemOtpVerification = false

          // set the current status of the redemption req that has been processed
          this.redeemReq = this.redemptionDashboardObjList.find(e => e.pay_redeem_id == this.redeemReq.pay_redeem_id)
          setTimeout(() => {
            this.showRedemptionReqCnfModal = false
          }, 3000)
        }
      }
      catch (err) {
        console.error("Exception in redeemOtpVerify() and err: ", err.message)
      }
    },
    /**
     * resendRedemptionOtp
     */
    async resendRedemptionOtp () {
      try {
        const payload = {
          pay_redeem_id: this.redeemReq.pay_redeem_id
        }

        if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
          payload.org_admin_email = this.orgData.org_admin_email
          payload.org_id = this.orgData.org_id
        }
        else {
          // GidePreneur logged into its account
          payload.org_admin_email = this.saasOrgData.org_admin_email
          payload.org_id = this.saasOrgData.org_id
        }

        const sendOtpResp = await PayRedemption.payRedemptionOtpSend(this, payload)
        ApiResponse.responseMessageDisplay(this, sendOtpResp)
      }
      catch (err) {
        console.error("Exception in resendRedemptionOtp() and err: ", err.message)
      }
    },
    /**
     * goTo Revenue Dashboard
     */
    goToRevenueDashboard () {
      if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
        this.$router.push(`/revenue_dashboard?org_id=${this.saasOrgData && this.saasOrgData.org_id ? this.saasOrgData.org_id : this.orgData.org_id}`)
      }
      else {
        this.$router.push(`/revenue_dashboard`)
      }
    },
    /**
     * goto main dashboard
     */
    goToDashboard () {
      if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
        this.$router.push(`/dashboard?org_id=${this.saasOrgData && this.saasOrgData.org_id ? this.saasOrgData.org_id : this.orgData.org_id}`)
      }
      else {
        this.$router.push(`/dashboard`)
      }
    },
    /**
     * goto FAQList
     */
    gotoFAQList () {
      this.$router.push(`/faq_list?gidepreneur`)
    },
    /**
     * openPayoutDetailsViewModal
     */
    openPayoutDetailsViewModal (redeemData) {
      try {
        this.propPayoutDetails = {
          payout_tid: redeemData.payout_tid,
          payout_tid_img: JSON.parse(redeemData.payout_tid_img)[0].url,
          payout_amount: redeemData.payout_amount
        }
        this.showPayoutDetailsViewModal = true
      }
      catch (err) {
        console.error("Exception in openPayoutDetailsViewModal() and err: ", err.message)
      }
    },
    /**
     * closePayoutDetailsViewModal
     */
    closePayoutDetailsViewModal () {
      this.showPayoutDetailsViewModal = false
    }
  }
}
</script>
<style lang="">
</style>
